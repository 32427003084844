import logo from "./assets/img/logo.png";
import dog from "./assets/img/dog-1.png";
import dog2 from "./assets/img/dog-2.png";
import x from "./assets/img/x.png";
import x2 from "./assets/img/x-2.png";
import tg from "./assets/img/tg.png";
import about from "./assets/img/about.png";
import home from "./assets/img/home.png";
import footerTitle from "./assets/img/footer-title.png";
import bgFooter from "./assets/img/bg-footer.png";
import tax from "./assets/img/tax.png";
import symbol from "./assets/img/symbol.png";
import lq from "./assets/img/lq.png";
import howtobuyleft from "./assets/img/howtobuy-left.png";
import howtobuyright from "./assets/img/howtobuy-right.png";

import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="relative container mx-auto px-2 lg:px-5 flex flex-col md:flex-row gap-5 justify-between items-center py-10">
        <a href="/" className="max-w-[300px]">
          <img src={logo} alt="logo" />
        </a>
        <ul className="flex gap-10 items-center text-lg">
          <li>
            <a href="/" className="transition-all hover:text-primary">
              Home
            </a>
          </li>
          <li>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all hover:text-primary"
            >
              Chart
            </a>
          </li>
          <li>
            <a href="#" className="transition-all hover:text-primary">
              Buy
            </a>
          </li>
          <li>
            <a href="#" className="transition-all hover:text-primary">
              How to buy
            </a>
          </li>
        </ul>

        <ul className="flex gap-5 items-center">
          <li>
            <a
              href="https://t.me/MyroMother"
              target="_blank"
              rel="noopener noreferrer"
              className="w-10"
            >
              <img alt="telegram" src={tg} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/MyroMother"
              target="_blank"
              rel="noopener noreferrer"
              className="w-10"
            >
              <img alt="x" src={x} />
            </a>
          </li>
        </ul>
      </header>

      <main>
        <section className="relative py-10 text-center flex flex-col items-center">
          <a className="absolute z-10 top-1/3 lg:top-2/3 left-1/2 -translate-x-1/2 lg:-translate-x-0 lg:left-1/4 bg-gradient w-64 py-4 rounded-lg border-[3px] transition-all hover:opacity-75">
            Buy on Raydium
          </a>
          <a className="absolute z-10 top-2/3 right-1/2 translate-x-1/2 lg:-translate-x-0 lg:right-1/4 bg-black w-64 py-4 rounded-lg border-[3px] transition-all hover:opacity-75">
            Chart
          </a>
          <div className="w-[160%] lg:w-full">
            <img alt="dog" src={dog} />
          </div>
        </section>

        <section id="about" className="py-20 container mx-auto px-2 lg:px-5">
          <h2 className="text-4xl lg:text-5xl font-bold text-center">
            About $MYROMOTHER
          </h2>
          <div className="flex flex-col-reverse lg:flex-row justify-between items-center gap-5 mt-10">
            <p className="max-w-lg leading-normal text-lg text-center lg:text-left">
              Enter the realm of MyroMother, the nurturing source of Myro—an
              enchanting decentralized cryptocurrency gracefully navigating the
              Solana blockchain. This foundational entity serves as the
              heartbeat of a community-driven movement, driven by a profound
              commitment to infuse accessibility, joy, and a mother's love into
              the tapestry of cryptocurrency.
              <br />
              <br />
              MyroMother transcends the mere concept of a digital currency; it
              symbolizes a tender revolution in its infancy. Fueled by an
              unwavering belief in the transformative power of cryptocurrency,
              we are dedicated to crafting a world where accessibility, delight,
              and the warmth of a mother's love converge seamlessly. This
              commitment extends to every member of our community, even the
              tiniest enthusiasts, as we embrace the spirit of familial
              connection within the realm of decentralized possibilities.
            </p>
            <div className="max-w-md">
              <img src={about} alt="about" />
            </div>
          </div>
        </section>

        <section
          id="tokenomics"
          className="py-20 container mx-auto px-2 lg:px-5"
        >
          <h2 className="text-4xl lg:text-5xl font-bold text-center">
            Tokenomics
          </h2>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-20 mt-40">
            <div>
              <img src={symbol} alt="" />
            </div>
            <div className="lg:-mt-16">
              <img src={tax} alt="" />
            </div>
            <div>
              <img src={lq} alt="" />
            </div>
          </div>

          <div className="box-gradient-wrapper-2 rounded-full mt-20">
            <div className="box-gradient-2 px-10 py-6 rounded-full flex flex-col lg:flex-row justify-between">
              <h3 className="font-bold text-xl lg:text-2xl">Contract</h3>
              <span className="text-lg lg:text-2xl break-words">
                CmuNHgByasxkwnP6FyagDhwknpNrtsWmjrJ56VJp3iZk
              </span>
            </div>
          </div>
        </section>

        <section id="howtobuy" className="py-20 container mx-auto px-2 lg:px-5">
          <h2 className="text-4xl lg:text-5xl font-bold text-center">
            How To Buy $MYROMOTHER
          </h2>

          <div className="flex flex-col lg:flex-row justify-between gap-10 mt-20">
            <div className="max-w-xs">
              <img src={howtobuyleft} alt="" />
            </div>

            <div className="h-52 flex gap-2 max-w-3xl">
              <div className="hidden lg:block w-5">
                <img src={howtobuyright} className="w-full" />
              </div>
              <div className="h-full flex flex-col justify-between -mt-2">
                <span>
                  1. Embark on this venture by setting up a Phantom wallet.
                </span>
                <span>
                  2. Secure your journey by obtaining $SOL and transferring it
                  into your wallet.
                </span>
                <span>
                  3. Witness the transformation as you seamlessly exchange $SOL
                  for $MYROMOTHER on Raydium, by the following link.
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20 container mx-auto px-2 lg:px-5">
          <div className="tetxt-center">
            <h2 className="text-4xl text-center lg:text-5xl font-bold">
              Follow MyroMother for more!
            </h2>
            <ul className="flex gap-5 justify-center items-center mt-10">
              <li>
                <a
                  className="w-16"
                  href="https://t.me/MyroMother"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="telegram" src={tg} />
                </a>
              </li>
              <li>
                <a
                  className="w-16"
                  href="https://twitter.com/MyroMother"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="x" src={x} />
                </a>
              </li>
            </ul>
          </div>
        </section>
      </main>

      <footer className="pt-20 relative">
        <div className="relative container mx-auto px-2 lg:px-5">
          <div className="bg-gradient mb-10 border-4 py-10 lg:py-20 px-5 lg:px-16 lg:rounded-full flex flex-col lg:flex-row gap-5 justify-between items-center">
            <div className="opacity-50 lg:opacity-100 max-w-md absolute right-20 -top-16">
              <img src={dog2} alt="dog-2" />
            </div>
            <div className="max-w-2xl relative flex flex-col gap-2">
              <div className="max-w-[280px]">
                <img src={logo} alt="" />
              </div>
              <h3 className="text-lg font-bold">
                MyroMother, the genesis of Myro, cradles within her embrace a
                captivating decentralized cryptocurrency, navigating the
                expansive pathways of the Solana blockchain.
                <br />
                <br />@ 2023 Myro, All Rights Reserved
              </h3>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
